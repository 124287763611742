import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HTMLtoJSX } from '../../../../../../helpers';
import {
  HeroModuleLocationAddressName,
  HeroModuleLocationDate,
  HeroModuleLocationSeparator,
  HeroModuleLocationWrapper,
} from '../../styles';

const HeroModuleLocation = ({
  condensed,
  siteHeader: { formattedDate, venueName, venueCity, shortInfo } = {},
  style,
}) => (
  <HeroModuleLocationWrapper
    condensed={condensed}
    style={style}
    data-testid="hero-module-location"
  >
    {formattedDate && (
      <HeroModuleLocationDate>
        {HTMLtoJSX(formattedDate)}
        {condensed ? (
          <br />
        ) : (
          <HeroModuleLocationSeparator>|</HeroModuleLocationSeparator>
        )}
      </HeroModuleLocationDate>
    )}
    <HeroModuleLocationAddressName>
      {condensed
        ? HTMLtoJSX([venueName, venueCity].filter(Boolean).join(',<br />'))
        : [venueName, venueCity].filter(Boolean).join(', ')}
    </HeroModuleLocationAddressName>
    {shortInfo && HTMLtoJSX(shortInfo)}
  </HeroModuleLocationWrapper>
);

HeroModuleLocation.propTypes = {
  condensed: PropTypes.bool,
  siteHeader: PropTypes.shape({
    formattedDate: PropTypes.string,
    venueName: PropTypes.string,
    venueCity: PropTypes.string,
    shortInfo: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  siteHeader: state.siteHeader.data,
});

export default connect(mapStateToProps)(HeroModuleLocation);

// Display styles
export const IMAGE = 'IMAGE';
export const VIDEO = 'VIDEO';
export const IMAGERIGHT = 'IMAGERIGHT';
export const HALF_STACKED = 'HALF_STACKED';
export const LARGE_IMAGE = 'LARGE_IMAGE';
export const LARGE_VIDEO = 'LARGE_VIDEO';
export const STANDARD = 'STANDARD';
export const LIGHT = 'LIGHT';
export const CARD = 'CARD';
export const XL_VIDEO = 'XL_VIDEO';
export const BACKGROUND_50_50 = 'BACKGROUND_50_50';
export const FOREGROUND_50_50 = 'FOREGROUND_50_50';

// Text alignment
export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';
export const CENTRE = 'CENTRE';

// Delivery types
export const CLASSROOM = 'CLASSROOM';

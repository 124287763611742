import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { siteTypesEnum } from '../../../../../../helpers';
import HeroModuleLocation from '../HeroModuleLocation';
import HeroModuleCourseOptions from '../HeroModuleCourseOptions';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

const { EVENT, COURSE } = siteTypesEnum();

const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements?.summary)}
`;

const HeroModuleSummary = (props) => {
  const { condensed, pageConfig: { siteType } = {}, style } = props;

  let content;
  switch (siteType) {
    case EVENT: {
      content = <HeroModuleLocation condensed={condensed} style={style} />;
      break;
    }
    case COURSE: {
      content = <HeroModuleCourseOptions condensed={condensed} style={style} />;
      break;
    }
    default: {
      content = null;
      break;
    }
  }

  return <Wrapper>{content}</Wrapper>;
};

HeroModuleSummary.propTypes = {
  condensed: PropTypes.bool,
  pageConfig: PropTypes.shape({
    siteType: PropTypes.oneOf(Object.keys(siteTypesEnum())),
  }),
};

const mapStateToProps = (state) => ({
  pageConfig: state.pageConfig,
});

export default connect(mapStateToProps)(HeroModuleSummary);

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { BackgroundVideoBody, BackgroundVideoOverlay } from '../../styles';
import { BackgroundVideoCore } from '../BackgroundVideoCore';
import { BackgroundVideoWistia } from '../BackgroundVideoWistia';
import { useOnScreen } from '../../../../../../hooks/useOnScreen';

export function BackgroundVideo(props) {
  const {
    videoId,
    videoSource,
    forcePlay,
    withOverlay,
    withTransparentOverlay,
  } = props;

  const [fadeIn, setFadeIn] = useState(null);
  const [videoControl, setVideoControl] = useState(null);
  const componentRef = useRef(null);
  const isOnScreen = useOnScreen(componentRef);
  const themeConfig = useContext(ThemeContext);

  const vStyles = themeConfig.module.vStyles;
  const showOverlay = withOverlay || vStyles.withOverlay;

  useEffect(() => {
    if (videoControl) {
      if (isOnScreen || forcePlay) {
        videoControl.play();
      } else {
        videoControl.pause();
      }
    }
  }, [forcePlay, isOnScreen, videoControl]);

  const handleStartPlaying = useCallback(() => {
    setFadeIn(true);
  }, []);

  const handlePauseVideo = () => {
    if (!videoControl) return;

    const videoControlState =
      videoControl.state && videoControl.state() !== 'paused';

    if (videoControl.paused === false || videoControlState) {
      videoControl.pause();
    } else {
      videoControl.play();
    }
  };

  return (
    <div onClick={handlePauseVideo} data-testid="background-video">
      <BackgroundVideoBody fadeIn={fadeIn} ref={componentRef}>
        {videoSource === 'WISTIA' && (
          <BackgroundVideoWistia
            videoId={videoId}
            setVideoControl={setVideoControl}
            onPlaying={handleStartPlaying}
          />
        )}
        {videoSource === 'CORE' && (
          <BackgroundVideoCore
            videoId={videoId}
            setVideoControl={setVideoControl}
            onPlaying={handleStartPlaying}
          />
        )}
      </BackgroundVideoBody>
      {withTransparentOverlay && (
        <BackgroundVideoOverlay
          transparent
          data-testid="background-video-transparent-overlay"
        />
      )}
      {showOverlay && (
        <BackgroundVideoOverlay data-testid="background-video-overlay" />
      )}
    </div>
  );
}

BackgroundVideo.propTypes = {
  videoId: PropTypes.string,
  embedOptions: PropTypes.object,
  withOverlay: PropTypes.bool,
  withTransparentOverlay: PropTypes.bool,
  onClick: PropTypes.func,
};

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const HeroModuleTextBox = styled.div`
  text-align: left;

  .row {
    align-items: center;
  }
`;

export const HeroModuleTextBoxWrapper = styled.div`
  padding: 35px 18px 40px;
  border-top: 4px solid transparent;

  .c-cta {
    width: auto;
  }

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements?.box)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements?.box)}
`;

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const ShortTextWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements?.shortText?.alignStyles?.[
        theme.module.alignStyle
      ],
    )}
`;

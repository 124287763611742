import { useEffect, useRef, useState } from 'react';

export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  const updateIsOnScreen = (entries) => {
    entries.forEach((entry) => {
      if (entry.target === ref.current) {
        setIsOnScreen(entry.isIntersecting);
      }
    });
  };

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(updateIsOnScreen, {
      threshold: 0.1,
    });

    if (ref.current) {
      observerRef.current.observe(ref.current);

      // Set the initial state of isOnScreen to true if the element is already intersecting
      const isIntersectingRecords = observerRef.current
        .takeRecords()
        .some((entry) => entry.isIntersecting);

      if (isIntersectingRecords) {
        setIsOnScreen(true);
      }
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [ref]); // eslint-disable-line

  return isOnScreen;
}

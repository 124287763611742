import styled from 'styled-components';
import { devices } from '../../../../helpers';

export const CardWrapper = styled.div`
  &&& {
    @media only screen and (max-width: ${devices.breakpoints.tablet}) {
      > .container {
        padding: 0;

        > .row {
          margin: 0;

          > [class^='col-'] {
            padding: 0;
          }
        }
      }
    }
  }
`;

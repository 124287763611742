import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const SubTitle = styled.h3`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements?.subTitle)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements?.subTitle)}
  a {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements?.subTitle?.elements?.link,
      )}
  }
  ${({ theme, colorType }) =>
    colorType &&
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.subTitle?.settings?.[colorType],
    )}
`;

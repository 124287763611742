import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { CallToAction } from '../../../sharedComponents';
import { CallToActionContainer } from '../../styles';

export const HeroModuleCallToAction = ({
  primaryCTAConfig,
  secondaryCTAConfig,
}) => {
  const themeConfig = useContext(ThemeContext);
  const vStyles = themeConfig.module.vStyles;
  const dStyles = themeConfig.module.dStyles;

  const {
    settings: { primaryLook, secondaryLook },
  } = dStyles.elements?.callToActionContainer;

  const {
    settings: { size },
  } = dStyles.elements?.callToActionContainer?.elements?.callToAction;

  const { settings: { mode } = {} } =
    vStyles.elements?.callToActionContainer?.elements?.callToAction || {};

  return (
    <CallToActionContainer data-testid="hero-module-call-to-action-container">
      {primaryCTAConfig?.shown && (
        <CallToAction
          look={primaryLook}
          mode={mode}
          size={size}
          ctaConfig={primaryCTAConfig}
        />
      )}
      {secondaryCTAConfig?.shown && (
        <CallToAction
          look={secondaryLook}
          mode={mode}
          size={size}
          ctaConfig={secondaryCTAConfig}
        />
      )}
    </CallToActionContainer>
  );
};

HeroModuleCallToAction.propTypes = {
  primaryCTAConfig: PropTypes.object,
  secondaryCTAConfig: PropTypes.object,
};

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const HeroModuleContent = styled.div`
  z-index: 1;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles?.elements?.content)}

  ${({ theme, colorType }) =>
    colorType &&
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.content?.settings?.[colorType],
    )}
`;

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Title = styled.h2`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements?.title)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements?.title)}

  ${({ theme, isStyled }) =>
    isStyled &&
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.title?.styled,
    )}
  
  ${({ theme, colorType }) =>
    colorType &&
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.title?.settings?.[colorType],
    )}
`;

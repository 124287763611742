import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements?.overlay)}

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.overlay?.opacityType?.[
        theme.module.opacityType
      ],
    )}
`;

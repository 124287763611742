import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { CallToAction } from '../../../sharedComponents';
import {
  CardCallToActionContainer,
  CardCallToActionContainerPrimary,
  CardCallToActionContainerSecondary,
} from '../../styles';

export const HeroModuleCardCallToAction = ({
  primaryCTAConfig,
  secondaryCTAConfig,
  primaryCTAColor,
  secondaryCTAColor,
}) => {
  const themeConfig = useContext(ThemeContext);
  const vStyles = themeConfig.module.vStyles;
  const dStyles = themeConfig.module.dStyles;

  const {
    settings: { primaryLook, secondaryLook },
  } = dStyles.elements?.callToActionContainer;

  const {
    settings: { size },
  } = dStyles.elements?.callToActionContainer?.elements?.callToAction;

  const {
    settings: { mode },
  } = vStyles.elements?.callToActionContainer?.elements?.callToAction;

  return (
    <CardCallToActionContainer data-testid="hero-module-call-to-action-container">
      {primaryCTAConfig?.shown && (
        <CardCallToActionContainerPrimary colorType={primaryCTAColor}>
          <CallToAction
            look={primaryLook}
            mode={mode}
            size={size}
            ctaConfig={primaryCTAConfig}
          />
        </CardCallToActionContainerPrimary>
      )}
      {secondaryCTAConfig?.shown && (
        <CardCallToActionContainerSecondary colorType={secondaryCTAColor}>
          <CallToAction
            look={secondaryLook}
            mode={mode}
            size={size}
            ctaConfig={secondaryCTAConfig}
          />
        </CardCallToActionContainerSecondary>
      )}
    </CardCallToActionContainer>
  );
};

HeroModuleCardCallToAction.propTypes = {
  primaryCTAConfig: PropTypes.object,
  secondaryCTAConfig: PropTypes.object,
  primaryCTAColor: PropTypes.string,
  secondaryCTAColor: PropTypes.string,
};

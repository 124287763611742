import styled from 'styled-components';
import { devices } from '../../../../helpers';

export const HeroModuleCourseOptionsWrapper = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

export const HeroModuleCourseOption = styled.span`
  margin-bottom: 10px;

  @media only screen and (max-width: ${devices.breakpoints.tablet}) {
    display: block;
  }
`;

export const HeroModuleCourseOptionsSeparator = styled.span`
  display: inline-block;
  padding: 0 10px;

  @media only screen and (max-width: ${devices.breakpoints.tablet}) {
    display: none;
  }
`;

export const HeroModuleCourseOptionsDate = styled.span`
  font-weight: bold;
`;

export const HeroModuleCourseOptionsShortInfo = styled.div`
  margin-top: 10px;
`;

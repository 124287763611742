import styled from 'styled-components';
import { devices, renderStyledElementStyles } from '../../../../helpers';

export const CardCallToActionContainer = styled.div`
  &&& {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles?.elements?.callToActionContainer,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles?.elements?.callToActionContainer,
      )}

    div {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles?.elements?.callToActionContainer?.elements
            ?.callToAction,
        )}

      &:not(:last-child) {
        ${({ theme }) =>
          renderStyledElementStyles(
            theme,
            theme.module.dStyles?.elements?.callToActionContainer?.elements
              ?.callToAction?.notLastChild,
          )}
      }

      &:last-child {
        ${({ theme }) =>
          renderStyledElementStyles(
            theme,
            theme.module.dStyles?.elements?.callToActionContainer?.elements
              ?.callToAction?.lastChild,
          )}
      }

      @media only screen and (min-width: ${devices.breakpoints.tablet}) {
        display: inline-block;
      }
    }
  }
`;

export const CardCallToActionContainerPrimary = styled(
  CardCallToActionContainer,
)`
  &&& {
    a {
      ${({ theme, colorType }) =>
        colorType
          ? renderStyledElementStyles(
              theme,
              theme.module.vStyles?.elements?.callToActionContainer?.elements
                ?.callToAction?.primary?.colorSettings?.[colorType],
            )
          : renderStyledElementStyles(
              theme,
              theme.module.vStyles?.elements?.callToActionContainer?.elements
                ?.callToAction,
            )}
    }
  }
`;

export const CardCallToActionContainerSecondary = styled(
  CardCallToActionContainer,
)`
  &&& {
    a {
      ${({ theme, colorType }) =>
        colorType
          ? renderStyledElementStyles(
              theme,
              theme.module.vStyles?.elements?.callToActionContainer?.elements
                ?.callToAction?.secondary?.colorSettings?.[colorType],
            )
          : renderStyledElementStyles(
              theme,
              theme.module.vStyles?.elements?.callToActionContainer?.elements
                ?.callToAction,
            )}
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { HTMLtoJSX, removeTagsFromString } from '../../../../../../helpers';
import { SubTitle } from '../../styles';

export const HeroModuleSubTitle = ({ text, colorType, style }) => {
  if (!text) {
    return null;
  }

  return (
    <SubTitle className="formatted-text" colorType={colorType} style={style}>
      {HTMLtoJSX(
        removeTagsFromString(text, ['br', 's', 'em', 'u', 'strong', 'a']),
      )}
    </SubTitle>
  );
};

HeroModuleSubTitle.propTypes = {
  text: PropTypes.string,
  colorType: PropTypes.string,
};

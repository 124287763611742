import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const ModuleWrapper = styled.div`
  position: relative;
  background: center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}

  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.module.dStyles?.backgroundImageStyle?.[
        theme.module.backgroundImageStyle
      ],
    );
  }}
    
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles?.alignStyles?.[theme.module.alignStyle],
    )}

  ${({ theme, minHeight }) => {
    if (minHeight) {
      return renderStyledElementStyles(theme, minHeight);
    }
  }}
`;

import React from 'react';
import PropTypes from 'prop-types';
import { PreTitle } from '../../styles';

export const HeroModulePreTitle = ({ text, style }) =>
  text ? <PreTitle style={style}>{text}</PreTitle> : null;

HeroModulePreTitle.propTypes = {
  text: PropTypes.string,
};

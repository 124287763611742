import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CallToActionContainer = styled.div`
  &&& {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles?.elements?.callToActionContainer,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles?.elements?.callToActionContainer,
      )}

    a {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles?.elements?.callToActionContainer?.elements
            ?.callToAction,
        )}
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles?.elements?.callToActionContainer?.elements
            ?.callToAction,
        )}

      &:not(:last-child) {
        ${({ theme }) =>
          renderStyledElementStyles(
            theme,
            theme.module.dStyles?.elements?.callToActionContainer?.elements
              ?.callToAction?.notLastChild,
          )}
      }

      &:last-child {
        ${({ theme }) =>
          renderStyledElementStyles(
            theme,
            theme.module.dStyles?.elements?.callToActionContainer?.elements
              ?.callToAction?.lastChild,
          )}
      }
    }
  }
`;

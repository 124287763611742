import styled from 'styled-components';
import { devices } from '../../../../helpers';

export const ForegroundImageRightWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 50%;
  background-size: cover;
  background-position: top;
  max-height: 100%;

  @media only screen and (max-width: ${devices.breakpoints.tablet}) {
    display: none;
  }

  img {
    display: block;
    width: 100%;
    margin-top: 30px;

    @media only screen and (min-width: ${devices.breakpoints.tablet}) {
      display: none;
    }
  }
`;

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const BodyWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles?.elements?.body?.alignStyles?.[
        theme.module.alignStyle
      ],
    )}
`;

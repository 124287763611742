import styled from 'styled-components';

export const HeroModuleLocationWrapper = styled.div`
  margin-bottom: 20px;
  font-size: ${({ condensed }) => (condensed ? '14px' : '16px')};
`;

export const HeroModuleLocationDate = styled.span`
  font-weight: bold;
`;

export const HeroModuleLocationSeparator = styled.span`
  display: inline-block;
  padding: 0 10px;
`;

export const HeroModuleLocationAddressName = styled.span``;

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const PreTitle = styled.h4`
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.2;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements?.preTitle)}
`;

import styled from 'styled-components';
import { devices } from '../../../../helpers';

export const ForegroundImageLogoRightWrapper = styled.span`
  display: none;

  @media only screen and (max-width: ${devices.breakpoints.tablet}) {
    position: relative;
    width: auto;
    background-image: none !important;
    max-width: 150px;
    display: block;
  }

  img {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
`;

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Text = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements?.text)}

  a {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements?.text?.elements?.link,
      )}
  }

  ${({ theme, colorType }) =>
    colorType &&
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements?.subTitle?.settings?.[colorType],
    )}
`;

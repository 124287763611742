import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FullSizeVideo } from '../../styles/CoreBackgroundVideo';
import clientConfig from '../../../../../../clientConfig';
import { useOnScreen } from '../../../../../../hooks/useOnScreen';

export const BackgroundVideoCore = ({
  videoId,
  onPlaying,
  setVideoControl,
}) => {
  const componentRef = useRef(null);
  const isOnScreen = useOnScreen(componentRef);

  useEffect(() => {
    const currentElement = componentRef.current;

    if (currentElement && currentElement.play) {
      //  https://github.com/facebook/react/issues/10389
      currentElement.setAttribute('muted', '');
      setVideoControl(currentElement);

      if (isOnScreen) {
        currentElement.play();
      } else {
        currentElement.pause();
      }
    }
  }, [setVideoControl, isOnScreen]);

  return isOnScreen ? (
    <FullSizeVideo
      autoPlay
      muted
      loop
      playsInline
      onPlaying={onPlaying}
      ref={componentRef}
      data-testid="background-video-core"
      src={`${clientConfig.coreVideoHost}${videoId}`}
      type="video/mp4"
    />
  ) : (
    <div ref={componentRef} style={{ width: '100%', height: '100%' }} />
  );
};

BackgroundVideoCore.propTypes = {
  videoId: PropTypes.string,
  onClick: PropTypes.func,
  onPlaying: PropTypes.func,
};

import styled from 'styled-components';

export const BackgroundVideoBody = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ fadeIn }) => (fadeIn ? 1 : 0)};
  transition: ${({ fadeIn }) => (fadeIn ? 'opacity 750ms ease' : 'none')};
`;

export const BackgroundVideoOverlay = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background: ${({ transparent }) =>
    transparent ? 'none' : 'rgba(0, 0, 0, 0.4)'};
`;

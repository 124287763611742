import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import addScriptTag from '../../../../../../utils/addScriptTag';
import { useOnScreen } from '../../../../../../hooks/useOnScreen';

const defaultOptions = {
  autoPlay: true,
  controlsVisibleOnLoad: false,
  copyLinkAndThumbnailEnabled: false,
  endVideoBehavior: 'loop',
  fitStrategy: 'cover',
  fullscreenButton: false,
  muted: true,
  playbackRateControl: false,
  playbar: false,
  playPauseNotifier: false,
  playSuspendedOffScreen: false,
  qualityControl: false,
  settingsControl: false,
  silentAutoPlay: 'allow',
  volumeControl: false,
  wmode: 'transparent',
  plugin: {
    cropFill: {
      src: '//fast.wistia.com/labs/crop-fill/plugin.js',
    },
  },
};

export const BackgroundVideoWistia = ({
  videoId,
  setVideoControl,
  onPlaying,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const videoRef = useRef(null);
  const isOnScreen = useOnScreen(videoRef);

  useEffect(() => {
    if (!document.getElementById('wistia_script')) {
      addScriptTag(
        'https://fast.wistia.com/assets/external/E-v1.js',
        true,
        'wistia_script',
      );
    }
  }, []);

  useEffect(() => {
    if (!(isOnScreen && !isInitialized)) return;

    window._wq = window._wq || [];
    window._wq.push({
      id: videoId,
      options: defaultOptions,
      onReady: (video) => {
        video.pause();
        video.time(0);
        video.play();
        setVideoControl(video);
        onPlaying();
        return video.unbind('play');
      },
    });

    setIsInitialized(true);
  }, [isOnScreen, videoId, setVideoControl, onPlaying, isInitialized]);

  const className = cn({
    [`wistia_embed wistia_async_${videoId}`]: isOnScreen,
  });

  return (
    <div
      ref={videoRef}
      className={className}
      data-testid="background-video-wistia"
    />
  );
};

BackgroundVideoWistia.propTypes = {
  videoId: PropTypes.string,
  onClick: PropTypes.func,
  onPlaying: PropTypes.func,
};

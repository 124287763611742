import React from 'react';
import PropTypes from 'prop-types';
import { HTMLtoJSX } from '../../../../../../helpers';
import { Text } from '../../styles';

export const HeroModuleText = ({ text, colorType, style }) =>
  text ? (
    <Text className="formatted-text" colorType={colorType} style={style}>
      {HTMLtoJSX(text)}
    </Text>
  ) : null;

HeroModuleText.propTypes = {
  text: PropTypes.string,
  colorType: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '../../styles';
import { HTMLtoJSX, removeTagsFromString } from '../../../../../../helpers';

export const HeroModuleTitle = ({
  as: titleSize = 'h2',
  text,
  styled,
  colorType,
  style,
}) => {
  if (!text) {
    return null;
  }

  const as = titleSize.toLowerCase();
  const isStyled = as === 'h1' || styled;

  return (
    <Title
      as={as}
      className="formatted-text"
      isStyled={isStyled}
      colorType={colorType}
      style={style}
    >
      {HTMLtoJSX(
        removeTagsFromString(text, ['br', 's', 'em', 'u', 'strong', 'a']),
      )}
    </Title>
  );
};

HeroModuleTitle.propTypes = {
  text: PropTypes.string,
  h1: PropTypes.bool,
  styled: PropTypes.bool,
  colorType: PropTypes.string,
};

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const ImageWrapper = styled.div`
  ${({ theme, displayType }) =>
    displayType &&
    theme.module.dStyles.elements?.imageWrapper?.alignStyles?.[displayType]
      ? renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.imageWrapper?.alignStyles?.[
            displayType
          ],
        )
      : renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.imageWrapper,
        )};
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { renderHTMLString } from '../../../../../../helpers';
import { getLimitedCourseOptions } from '../../../../../../store/siteDucks/siteHeader';
import { CLASSROOM } from '../../constants';
import {
  HeroModuleCourseOption,
  HeroModuleCourseOptionsDate,
  HeroModuleCourseOptionsSeparator,
  HeroModuleCourseOptionsShortInfo,
  HeroModuleCourseOptionsWrapper,
} from '../../styles';

const HeroModuleCourseOptions = (props) => {
  const {
    siteHeader: { shortInfo } = {},
    options: { data: { deliveryType } = {} } = {},
    limitedCourseOptions = [],
    style,
  } = props;

  const { t } = useTranslation();

  return (
    <HeroModuleCourseOptionsWrapper
      style={style}
      data-testid="hero-module-course-options"
    >
      {limitedCourseOptions.map((option, index) => {
        const { dateAsText, venue: { venueName } = {} } = option;
        const {
          dateAsText: prevDateAsText,
          venue: { venueName: prevVenueName } = {},
        } = limitedCourseOptions[index - 1] || {};
        const showSeparator =
          (prevDateAsText || (deliveryType === CLASSROOM && prevVenueName)) &&
          (dateAsText || (deliveryType === CLASSROOM && venueName));

        return (
          <HeroModuleCourseOption key={index}>
            {index > 0 && showSeparator && (
              <>
                {deliveryType === CLASSROOM ? (
                  <HeroModuleCourseOptionsSeparator>
                    |
                  </HeroModuleCourseOptionsSeparator>
                ) : (
                  <span> {t('course.option-date.separator')} </span>
                )}
              </>
            )}
            <span>
              {dateAsText && (
                <HeroModuleCourseOptionsDate>
                  {dateAsText}
                </HeroModuleCourseOptionsDate>
              )}
              {deliveryType === CLASSROOM && venueName && (
                <>
                  {dateAsText && ', '}
                  <span>{venueName}</span>
                </>
              )}
            </span>
          </HeroModuleCourseOption>
        );
      })}
      {shortInfo && (
        <HeroModuleCourseOptionsShortInfo>
          {renderHTMLString(shortInfo)}
        </HeroModuleCourseOptionsShortInfo>
      )}
    </HeroModuleCourseOptionsWrapper>
  );
};

HeroModuleCourseOptions.propTypes = {
  siteHeader: PropTypes.shape({
    shortInfo: PropTypes.string,
    courseOptions: PropTypes.array,
  }),
  options: PropTypes.shape({
    data: PropTypes.shape({
      deliveryType: PropTypes.string,
    }),
  }),
  limitedCourseOptions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  siteHeader: state.siteHeader.data,
  options: state.options,
  limitedCourseOptions: getLimitedCourseOptions(state),
});

export default connect(mapStateToProps)(HeroModuleCourseOptions);

import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export function useShowBackgroundVideo(videoId, playOnMobile = false) {
  const [showVideo, setShowVideo] = useState(false);
  const shouldPlayOnDevice = isMobile ? playOnMobile : true;

  useEffect(() => {
    setShowVideo(videoId && shouldPlayOnDevice);
  }, [videoId, shouldPlayOnDevice]);

  return showVideo;
}
